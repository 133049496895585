.form-wrapper {
    border-radius: 15px;
    /*padding: 20px;*/
    transform: perspective(1000px);
    transform-style: preserve-3d;
  }
  
  .form-column {
    perspective: 1000px;
  }
  
  .input-group {
    transform: translateZ(30px);
    margin-bottom: 20px;
  }
  
  .form-input, .form-select, .form-textarea {
    background: white;
    border: 1px rgb(209 213 219 0.5);
    padding: 10px;
    border-radius: 8px;
    font-size: 16px;
  }
  
  .submit-button {
    box-shadow: 5px 5px 10px #d9d9d9, -5px -5px 10px #ffffff;
    transition: all 0.3s ease-in-out;
  }
  
  .submit-button:hover {
    transform: translateY(-2px);
  }

  